@import "../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  padding: 16px 0;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 16px;
}

.error {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color:  var(--error-500, #F04438);
  margin-bottom: 4px;
}

.box {
  position: relative;
  min-height: 45px;
}

.remove {
  margin: auto 0 auto auto;
}


.row {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.labelWrapper {
  width: 280px;
  flex-shrink: 0;
}

.label {
  color: var(--base-white, #FFF);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.labelRight {
  flex-grow: 1;
  margin-right: auto;
  max-width: calc(100% - 465px);
  display: flex;
  flex-direction: column;
  position: relative;
}
