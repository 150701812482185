@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
*,
::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

:root {
    --app-height: 100%;
    --blue-light-600: #0086C9;
    --blue-light-500: #0BA5EC;
    --blue-light-400: #36BFFA;
    --gray-950: #0C111D;
    --gray-900: #101828;
    --gray-850: #162136;
    --gray-800: #1D2939;
    --gray-750: #2B3B5B;
    --gray-700: #344054;
    --gray-650: #202C43;
    --gray-600: #475467;
    --gray-550: #354462;
    --gray-500: #667085;
    --gray-450: #858CA2;
    --gray-400: #99A0A8;
    --gray-300: #D0D5DD;
    --gray-200: #EAECF0;
    --gray-100: #F2F4F7;
    --gray-25: #FCFCFD;
    --gray-blue-300: #B3B8DB;
    --green-300: #75E0A7;
    --green-500: #17B26A;
    --green-700: #2c734f;
    --error-400: #F97066;
    --error-500: #F04438;
    --error-950: #55160C;
    --color-white: #ffffff;
    --system-colors-gray-200: #E0E3E6;
    --system-colors-gray-300: #A4B0C9;
    --system-colors-gray-400: #99A0A8;
    --system-colors-gray-600: #464D59;
    --primary-200: #E9D7FE;
    --gray-iron-50: #FAFAFA;
    --orange-500: #FF9761;
    --yellow-200: #FEDF89;
    --yellow-300: #FAC515;
    --yellow-400: #FDB022;
    --yellow-500: #EAAA08;
    --yellow-600: #F79009;
    --yellow-800: #8E6B28;
    --media-md: 900;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

body {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--gray-950);
    color: var(--color-white);
    overflow-x: hidden;
    min-height: 100svh;
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
}
hr:not([size]) {
    height: 1px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0;
}
h1 {
    font-size: calc(1.375rem + 1.5vw);
}
h2 {
    font-size: calc(1.325rem + 0.9vw);
}
h3 {
    font-size: calc(1.3rem + 0.6vw);
}
h4 {
    font-size: calc(1.275rem + 0.3vw);
}
h5 {
    font-size: 1.25rem;
}
h6 {
    font-size: 1rem;
}
abbr[data-bs-original-title],
abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}
address {
    font-style: normal;
    line-height: inherit;
}
ul {
    list-style-type: none;
}
dt {
    font-weight: 700;
}
b,
strong {
    font-weight: bolder;
}
small {
    font-size: 0.875em;
}
mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}
sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
a {
    display: block;
    color: inherit;
    text-decoration: none;
}
a:hover {
    color: inherit;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override;
}
pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}
code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
}
a > code {
    color: inherit;
}
kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}
kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
}
figure {
    margin: 0 0 1rem;
}
img {
    display: block;
}
table {
    caption-side: bottom;
    border-collapse: collapse;
}
caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left;
}
th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
label {
    display: inline-block;
}
button {
    border: none;
    background: none;
    border-radius: 0;
}
button:focus:not(:focus-visible) {
    outline: 0;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    resize: none!important;
}
button,
select {
    text-transform: none;
}
[role="button"] {
    cursor: pointer;
}
select {
    word-wrap: normal;
}
select:disabled {
    opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
    display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
::-moz-focus-inner {
    padding: 0;
    border-style: none;
}
textarea {
    resize: vertical;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
}
legend + * {
    clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0;
}
::-webkit-inner-spin-button {
    height: auto;
}
[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}
::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
    padding: 0;
}
::file-selector-button {
    font: inherit;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
output {
    display: inline-block;
}
iframe {
    border: 0;
}
summary {
    display: list-item;
    cursor: pointer;
}
progress {
    vertical-align: baseline;
}
[hidden] {
    display: none !important;
}

img {
    max-width: 100%;
}

a,
.MuiLink-root {
    text-decoration: none!important;
}

.apexcharts-gridline {
    stroke-width: 1px!important;
    opacity: 0.2!important;
}

.apexcharts-tooltip {
    box-shadow: none!important;
    background: transparent!important;
}
