@import "styles/mixins";

.wrapper {
  border-top: 1px solid var(--gray-700);
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  flex-wrap: wrap;

  @include for-size(xs) {
    margin-left: -24px;
    margin-right: -24px;
  }
}

.col   {
  flex: 0 0 100%;

  @include for-size(md) {
    width: 50%;
    flex: 0 0 50%;
  }

  &:first-child {
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      border-right: 1px solid var(--gray-700);
      position: absolute;
      top: 0;
      right: 0;
    }
  }

}