.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 15px;
}

.subredditList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listItem {
  display: flex;
  gap: 5px;
  padding: 7px 10px;
  border-radius: 5px;
  border: 1px solid var(--gray-500);
}

.deleteFromList {
  cursor: pointer;
}

.listItemValue {
  flex: 1;
}

.newValueWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}