.title {
  color: var(--gray-300, #D0D5DD);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 16px;
  width: 100%;
}

.outerLink {
  color: var(--blue-light-400, #36BFFA);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 2px;
  transition: 0.3s;
  border-bottom: 1px solid transparent;
  width: max-content;

  &:hover {
    color: var(--blue-light-400, #36BFFA);
    border-bottom: 1px solid var(--blue-light-400, #36BFFA);
  }
}

.insightListItem {
  font-size: 15px;
  line-height: 22px;
  list-style-type: unset;
  display: list-item;
  margin-left: 15px;

  & + & {
    margin-top: 5px;
  }
}

.positive {
 color: var(--green-500);
}

.negative {
  color: var(--error-500);
}

.container {
  border-radius: 16px;
  padding: 16px 16px 16px 50px;
  margin-bottom: 16px;
  background-color: var(--gray-650);
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: inline;
    color: var(--blue-light-400, #36BFFA);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    padding-bottom: 2px;
    transition: 0.3s;
    border-bottom: 1px solid transparent;
    width: fit-content;

    &:hover {
      color: var(--blue-light-400, #36BFFA);
      border-bottom: 1px solid var(--blue-light-400, #36BFFA);
    }
  }
}

.containerTitle {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;

  svg {
    display: block;
    position: absolute;
    left: -32px;
    top: 2px;
  }
}

.content {
  color: var(--gray-300);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 6px;
  word-break: break-word;
}

.wrapper {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  height: 100%;
}

.toContent {
  top: 0;
  bottom: 0;
}

.center {
 align-items: center;
  justify-content: center;
}
