.chipsList {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.chip {
  padding: 4px 10px;
  background: var(--color-white);
  color: var(--gray-900);
  font-size: 14px;
  border-radius: 25px;
}

.input {
  border: none;
  background: transparent;
  outline: none;
  color: var(--color-white);
  flex: 1;
}

.wrapper {
  padding: 10px;
  border: 1px solid var(--system-colors-gray-600);
  border-radius: 8px;
  background: var(--gray-900);
}

.removeChip {
  margin-left: 2px;
  cursor: pointer;
}