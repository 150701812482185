.inputWithSearch {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.wrapper {
  position: relative;
  z-index: 10;
}
