@import "styles/mixins";

.listHeadWrapper {
  display: flex;
  margin-top: -10px;
}

.listRowData {
  display: flex;

  &.main {
    background-color: var(--gray-650);
  }
}

.col {
  flex: 1;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    border-right: 1px solid var(--gray-500);
  }
}

.firstCol {
  flex: 35%;
  border-right: 1px solid var(--gray-500);
}

.listWrapper {
  margin: 0 -12px;

  @include for-size(xs) {
    margin: 0 -24px;
  }

  &.noMargin {
    margin: 0;
  }
}

.skeletonWrapper {
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 15px 6px;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 15px 30px;
}

.mainStockText {
  margin-left: 10px;
  font-size: 12px;
  flex: 0 0 60px;
  font-weight: 400;
}

.titleWrapper {
  margin-left: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  flex: 1;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: var(--gray-750);
  flex: 0 0 46px;
}

.count {
  font-size: 26px;
  font-weight: 700;
  text-align: right;
}
