@import "styles/mixins";
.wrapper {
  display: flex;
  align-items: center;
  border-radius: 9px;
  background-color: var(--gray-700);
  padding: 5px 8px;
  flex-shrink: 0;
  margin: auto;

  @include for-size(md) {
    margin: 0 0 auto auto;
  }
}
