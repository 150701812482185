.responderLabel {
  padding: 7px 20px;
  border: 1px solid var(--green-500);
  color: var(--green-500);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  align-items: center;
  gap: 5px;
  transform: scale(0.8);
  transform-origin: center;
}
