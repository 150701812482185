.wrapper {
  padding: 32px 0 24px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}

.headingWrapper {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
