@import "styles/mixins";

.chartTitle {
  font-size: 18px;
}

.gaugeWrapper {
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-top: -20px;
  transform: translateY(32px);

  :global {
    .apexcharts-legend {
      padding: 0!important;
      background-color: transparent!important;
      min-height: auto!important;
      width: 85%;
      left: 50% !important;
      transform: translateX(-50%);

      @include for-size(xs) {
        flex-wrap: nowrap!important;
      }
    }

    .apexcharts-datalabels-group {
      text {
        fill: var(--gray-blue-300) !important;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        font-family: Inter;
        transform: translateY(-50px);

        + text {
          fill: var(--color-white) !important;
          font-family: Inter;
          font-size: 48px;
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -0.96px;
          transform: translateY(-30px);
        }
      }
    };

    .apexcharts-legend-series {
      width: calc(100% / 3)!important;
      justify-content: center;
      min-height: 60px;
    }
  }

}

.height {
  height: 100%;
  padding-bottom: 0;
}

.block {
  display: flex;
  flex-direction: column;
  padding-left: 14px;
}

.marker {
  position: relative;
  color: var(--gray-blue-300, #B3B8DB);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: -15px;
  top: 5px;
}

.labels {
  display: flex;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-white);

  >div {
    &:first-child {
      margin-right: 7px;
      padding-right: 7px;
      position: relative;

      &:after {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--color-white);
        position: absolute;
        top: 8px;
        right: -1px;
      }
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
