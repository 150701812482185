.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  position: relative;

  &.hiddenLoader {
    display: none;
  }

  margin-bottom: 24px;
}
