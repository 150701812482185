.wrapper {
  margin: 15px 0;
}

.title {
  font-size: 18px;
  margin-bottom: 10px;
}

.rows {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  flex-direction: column;

  .row {
    flex: 1;
    margin-top: 0!important;
  }
}

.sourcesButtons {
  display: flex;
  gap: 15px;
}

.queries {
  margin-top: 15px;
}

.firstCol {
  width: 150px;
}

.addQueryWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.row {
  display: flex;
  align-items: center;
  gap: 15px;

  & + &, & + .rows {
    margin-top: 5px;
  }
}