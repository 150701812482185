.wrapper {
  margin: 15px 0;
}

.title {
  font-size: 18px;
  margin-bottom: 10px;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
  & + & {
    margin-top: 10px;
  }
}

.sourceData {
  flex: 0 0 150px;
  display: flex;
  align-items: center;
  gap: 10px;
}