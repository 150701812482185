.inputWrapper {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 3;
  flex-direction: column;

  :global {
    .MuiSwitch-switchBase {
      color: #fff;
      position: absolute;
      width: max-content;
      transform: translate(0, 4px);
      left: 4px;
      right: auto;
      padding: 0;

      &:after {
        content: 'OFF';
        margin-left: 5px;
        font-size: 14px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .MuiSwitch-switchBase.Mui-checked {
      color: #fff;
      transform: translate(0, 4px);
      left: 11px;

      &:after {
        content: '';
      }

      &:before {
        font-size: 14px;
        content: 'ON';
        margin-right: 5px;
      }
    }

    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
      background-color: #06db60;
    }

    .MuiSwitch-track {
      border-radius: 20px;
    }

    .MuiSwitch-thumb {
      width: 24px;
      height: 24px;
    }

    .MuiSwitch-root {
      height: 31px;
      width: 66px;
      padding: 0;
    }
  }
}

.label {
  color: var(--gray-400);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  display: inline-block;
  margin-left: 5px;
}

.input {
  width: 100%;
  min-height: 44px;
  border-radius: 8px;
  border: 1px solid var(--system-colors-gray-600, #464D59);
  background: var(--gray-900, #101828);
  box-shadow: 0 1px 2px 0 rgba(13, 16, 23, 0.24);
  padding: 8px 14px;
  outline: none;
  transition: 0.3s;
  color: var(--system-colors-gray-400, #99A0A8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:not(.noFocus):focus {
    border-color: var(--system-colors-gray-400, #99A0A8);
  }

  &::placeholder {
    opacity: 0.5;
  }
}
