@import "styles/mixins";

.card {
  flex-grow: 1;
  position: relative;
  overflow: visible;
  border-radius: 16px;
  padding: 12px;

  @include for-size(xs) {
    padding: 24px;
  }
}

.noBorder {
  border: none;
  border-radius: 30px;
}
