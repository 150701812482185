.legends {
  display: flex;
  gap: 24px;
}

.legendText {
  font-size: 16px;
  color: #98A2B3;
}

.legend {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: .3s background-color;

  &:not(.active):hover {
    background-color: #1D2939;
  }

  &.active {
    background-color: #344054;

    .legendText {
      color: #EAECF0;
    }
  }
}