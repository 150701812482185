@import "styles/mixins";

.wrapper {
  display: flex;
  grid-gap: 4px;
  padding: 17px 8px 8px 8px;
  border-bottom: 1px solid var(--gray-800, #1D2939);
  position: relative;
  transition: background-color 0.3s;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover, &.active {
    background-color: var(--gray-800);
  }
}

.userHeading {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.index {
  display: flex;
  align-items: flex-start;
  transform: translateY(6px);
  color: var(--gray-25);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  min-width: 16px;

  @include for-size(xs) {
    align-items: center;
    transform: none;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  grid-gap: 16px;
  flex-grow: 1;
  flex-wrap: wrap;

  @include for-size(xs) {
    grid-gap: 32px;
  }
}

.labels {
  display: flex;
  grid-gap: 16px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 8px;
  flex-wrap: wrap;
  width: 100%;

  @include for-size(xs) {
    width: auto;
    margin-bottom: auto;
  }
}

.label {
  color: var(--gray-400);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.flex {
  display: flex;
  grid-gap: 8px;
}

.border {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}

.yellow {
  color: var(--yellow-500 );
}

.outerLink {
  color: var(--blue-light-400, #36BFFA);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 2px;
  transition: 0.3s;
  border-bottom: 1px solid transparent;
  width: max-content;

  &:hover {
    color: var(--blue-light-400, #36BFFA);
    border-bottom: 1px solid var(--blue-light-400, #36BFFA);
  }
}

