.status {
  text-transform: capitalize;
  padding: 3px 10px;
  border-radius: 20px;
  display: inline-block;

  &.completed {
    background-color: var(--green-700);
  }

  &.pending, &.progress {
    background-color: var(--yellow-800);
  }

  &.failed {
    background-color: var(--error-950);
  }

  &.archived {
    background-color: var(--yellow-800);
  }
}