@import "styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  grid-gap: 12px;
  backdrop-filter: blur(10px);
  background-colour: rgba(12, 17, 29, 0.8);
  position: relative;
  z-index: 2;

  flex-wrap: wrap;

  @include for-size(md) {
    flex-wrap: nowrap;
  }
}

.dateWrapper {
  margin: 0;
}
