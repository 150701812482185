.dragButton {
  position: absolute;
  right: 5px;
  top: 2px;
  cursor: pointer;
}

.orderItem {
  max-width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--gray-600);
  border-radius: 5px;
  background-color: var(--gray-850);
}

.innerList {
  margin-top: 10px;
  padding: 0 20px;
}

.submitWrapper {
  margin-top: 20px;
  button {
    width: 100%;
  }
}
