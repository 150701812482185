@import "styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.label {
  margin-left: 10px;
  padding: 4px;
  border-radius: 4px;
  background: var(--gray-800);
  color: #A1A7BB;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  &.smallLable {
    font-size: 10px;
    margin-left: 5px;
    padding: 2px;
  }
}

.skeleton {
  display: flex;
  align-items: center;
  width: 250px;
  grid-gap: 12px;
}

.title {
  max-width: 80%;

  &.smallTitle {
    font-size: 14px;
    line-height: 20px;
  }
}

.logo {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 7px;

  @include for-size(xs) {
    width: 32px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &>* {
    margin-right: 5px;
  }
}
