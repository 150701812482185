@import "styles/mixins";
.title {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  margin: 25px 0 10px;
}

.row {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
}

.sentimentRow {
  gap: 4px;
}

.statisticValue {
  display: inline-block;
  margin-right: 10px;
  margin-left: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: var(--gray-500);
}

.statisticVsValue {
  font-size: 16px;
}

.spacer {
  margin-top: 18px;
  justify-content: space-between;
  margin-bottom: 3px;
}

.red {
  color: var(--error-500)
}

.green {
  color: var(--green-500);
}

.wrapper {
  display: flex;
  flex: 1;
  min-height: 173px;
  min-width: 250px;

  @include for-size(xxs) {
    flex: 1 0 calc(50% - 12px);
  }

  @include for-size(md) {
    flex: 1;
    max-width: 30%;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.titleIcon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.card {
  padding: 18px 16px!important;
}

.chart {
  margin-bottom: 5px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.bar {
  height: 5px;
}

.chartValue {
  min-width: 61px;

  &:first-child {
    .bar {
      border-radius: 4px 0 0 4px;
    }
  }

  &:last-child {
    .bar {
      border-radius: 0 4px 4px 0;

    }
  }

  &.negative {
    color: inherit;
    .bar {
      background-color: var(--error-500);
    }
  }

  &.positive {
    color: inherit;
    .bar {
      background-color: var(--green-500);
    }
  }

  &.default {
    .bar {
      background-color: var(--system-colors-gray-300);
    }
  }
}

.valueWrapper {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartDataIcon {
  margin-right: 5px;
}

.negative {
  color: var(--error-500);

  path {
    stroke: var(--error-500);
  }
}

.positive {
  color: var(--green-500);

  path {
    stroke: var(--green-500);
  }
}

.default {
  flex-grow: 1;
}

.analyzeText{
  font-size: 12px;
}

.label {
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
  color: var(--gray-400, #98A2B3);
  font-size: 16px;

  svg {
    width: 15px;
    height: 15px;
    margin: 0 2px;
  }
}
