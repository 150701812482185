.error {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color:  var(--error-500, #F04438);;
}

.searchMenuItem {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: -8px;
}

.hiddenMenuItem {
  height: 0;
  visibility: hidden;
  padding: 0;
}

.searchWrapper {
  padding: 5px 18px;
  width: 100%;

  .searchInput {
    background: transparent;
    outline: none;
    border: none;
    display: block;
    width: 100%;
    padding: 5px 0;
    color: var(--color-white);
  }
}

.wrapper {
  flex-grow: 1;

  :global {
    .MuiInputBase-root {
      width: 100%!important;
    }

    .MuiSelect-root {
      color: var(--gray-400)!important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .MuiSelect-select {
      white-space: break-spaces;
    }

    .Mui-focused {
      color: var(--gray-400)!important;
    }

    fieldset {
      border: 1px solid var(--system-colors-gray-600, #464D59)!important;
      min-height: 44px;
      border-radius: 8px;
    }

    .MuiInputBase-root {
      &>div {
        padding: 11px 30px 11px 11px!important;
        background: var(--gray-900, #101828)!important;
      }
    }

    .MuiFormLabel-root {
      top: -5px;
    }
  }

  .labelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .label {
    color: var(--system-colors-gray-200, #E0E3E6);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}