.influenceInsights {
  margin-bottom: 12px;
}

.alertInflHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}

.contentActions {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.container {
  border-radius: 16px;
  padding: 16px 16px 16px 50px;
  margin-bottom: 16px;
  background-color: var(--gray-650);

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: inline;
    color: var(--blue-light-400, #36BFFA);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    position: relative;
    padding-bottom: 2px;
    transition: 0.3s;
    border-bottom: 1px solid transparent;
    width: fit-content;

    &:hover {
      color: var(--blue-light-400, #36BFFA);
      border-bottom: 1px solid var(--blue-light-400, #36BFFA);
    }
  }
}

.containerTitle {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;

  svg {
    display: block;
    position: absolute;
    left: -32px;
    top: 2px;
  }
}