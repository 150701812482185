.MuiContainer-maxWidthLg {
  max-width: 1424px!important;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark,
.apexcharts-yaxistooltip.apexcharts-theme-dark {
  background: var(--gray-700)!important;
  border: none!important;
  color: var(--gray-400)!important;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px!important;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
  margin-top: 10px;
}

.apexcharts-xaxistooltip-text {
  color: var(--gray-400, #98A2B3) !important;
  font-size: 12px!important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after,
.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: var(--gray-700)!important;
}

.apexcharts-yaxistooltip.apexcharts-yaxistooltip-left.apexcharts-theme-dark:before,
.apexcharts-yaxistooltip.apexcharts-yaxistooltip-left.apexcharts-theme-dark:after {
  border-left-color: var(--gray-700)!important;
}

.apexcharts-yaxistooltip.apexcharts-yaxistooltip-right.apexcharts-theme-dark:before,
.apexcharts-yaxistooltip.apexcharts-yaxistooltip-right.apexcharts-theme-dark:after {
  border-right-color: var(--gray-700)!important;
}

.Toastify__progress-bar--success {
  background: var(--green-500);
}

.Toastify__toast--success {
  svg {
    fill: var(--green-500);
  }
}

.Toastify__toast-theme--dark {
  background: var(--gray-950);
  border: 1px solid var(--gray-700);
}

.Toastify__toast--error {
  svg {
    fill: var(--error-500);
  }
}

.Toastify__progress-bar--error {
  background: var(--error-500);
}

.MuiPaper-root {
  background-color: var(--gray-900);
  border-color: var(--gray-700);
}

.MuiTableCell-root {
  color: var(--gray-100);
}

.MuiTableBody-root {
  .MuiTableRow-root {
    &:nth-of-type(odd) {
      background-color: var(--gray-800);
    }
  }
}
