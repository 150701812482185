@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 15px;
  overflow: visible;

  &.topWrapper {
    padding: 32px 0 24px 0;
  }

  &:last-child {
    padding-bottom: 24px;
  }

  @include for-size(xs) {
    grid-gap: 30px;
  }
}

.companyFilters {
  padding: 10px 0;
  background-color: var(--gray-950);
  position: sticky;
  top: 0;
  z-index: 16;
}
