.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 10px;
}

.topics {
  margin: 10px 0;
}

.details {
  margin-top: 15px;
}

.emailsTitle {
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
  border-top: 1px solid var(--gray-700);
}