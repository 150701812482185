.expandButton {
  background-color: #2E90FA;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &.expanded {
    background-color: #175CD3;
  }
}

.expandedCell {
  background-color: var(--gray-950);

  & > div:first-child {
    //background: none;
    //padding: 10px;
    //border: none;
  }
}