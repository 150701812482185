@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-700);
  position: relative;
  overflow: hidden;

  @include for-size(xs) {
    padding: 24px;
    margin-bottom: 24px;
  }
}

.moveResponderWrapper {
  display: flex;
  justify-content: flex-end;
}

.text {
  color: var(--gray-400);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex: 1;
}

.row {
  display: flex;
  align-items: flex-start;
  grid-gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include for-size(xs) {
    justify-content: space-between;

    & + & {
      margin-top: 16px;
    }
  }
}

.rightHeadCol {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
}

.labels {
  display: flex;
  grid-gap: 16px;
  margin-left: 0;
  margin-top: auto;
  margin-bottom: auto;

  @include for-size(xs) {
    margin-left: auto;
  }
}

.label {
  color: var(--gray-400);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.flex {
  display: flex;
  grid-gap: 8px;
}

.outerLink {
  color: var(--blue-light-400, #36BFFA);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  padding-bottom: 2px;
  transition: 0.3s;
  border-bottom: 1px solid transparent;
  margin-left: auto;
  width: fit-content;

  &:hover {
    color: var(--blue-light-400, #36BFFA);
    border-bottom: 1px solid var(--blue-light-400, #36BFFA);
  }
}
