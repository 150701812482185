.wrapper {
  border-radius: 6px;
  color: var(--gray-400, #98A2B3);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  transition: 0.3s;
  margin: 2px 8px;

  &.active {
    color: var(--color-white);
    background: var(--gray-800);
  }

  @media (hover: hover) {
    &:hover {
      background: var(--gray-700);
    }
  }
}

.icon {
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
}
