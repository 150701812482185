@import "styles/mixins";

.wrapper {
  background: var(--gray-650);
  border-radius: 24px;
  width: 100%;
  position: absolute;
  padding: 45px 60px;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 900px;
  text-align: center;
  box-shadow: 0 0 24px #101828;
}

.warning {
  margin-top: 15px;
}

.warningMessage {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.title {
  margin-bottom: 20px;
}

.subTitle {
  margin-bottom: 4px;
}
