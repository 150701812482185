.wrapper {
  flex-grow: 1;

  input {
    opacity: 0;
    height: 0;
  }

  .flexWrapper {
    width: 668px;
    >div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  :global {
    .react-datepicker {
      display: flex;
      flex-wrap: wrap;
    }

    .react-datepicker__day.react-datepicker__day--disabled{
      opacity: 0.3;

      &:hover {
       border: none;
      }
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      left: -8px;
    }

    .react-datepicker {
      background: var(--gray-800);
      border: 1px solid var(--gray-500);
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      border-bottom-color: var(--gray-800);
    }

    .react-datepicker__navigation--previous {
      top: 12px;
      left: 6px;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      padding: 10px;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .react-datepicker__day-name {
      font-family: Inter;
      color: var(--gray-100, #F2F4F7)!important;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name,
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--gray-100);
    }

    .react-datepicker__header {
      background: var(--gray-800);
      border: none;
      padding: 0 0 10px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      border: 1px solid var(--gray-500);
      background: var(--gray-800);
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
      border: 1px solid transparent;
      margin: 0;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      background: var(--gray-600, #475467);
    }


    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--range-start),
    .react-datepicker__day--in-range:not(.react-datepicker__day--range-start),
    .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--range-start),
    .react-datepicker__month-text--in-range:not(.react-datepicker__day--range-start),
    .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--range-start),
    .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--range-start),
    .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--range-start),
    .react-datepicker__year-text--in-range:not(.react-datepicker__day--range-start),
    {
      background: var(--gray-700);
      border-radius: 0;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start {
      position: relative;
      z-index: 1;

      +.react-datepicker__day--in-range,
      +.react-datepicker__day--range-end,
      +.react-datepicker__day--in-selecting-range {
        position: relative;
        z-index: 0;

        &:before {
          content: '';
          position: absolute;
          width: 14px;
          top: -1px;
          left: -15px;
          bottom: -1px;
          z-index: 0;
          background: var(--gray-700);
        }
      }

      +.react-datepicker__day--range-end {
        position: relative;
        z-index: 0;

        &:before {
          width: 30px;
        }
      }
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-start {
      &:before {
        opacity: 0;
      }
    }

    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end {
      position: relative;
      z-index: 1;
        &:before {
          content: '';
          position: absolute;
          width: 14px;
          top: -1px;
          left: -1px;
          bottom: -1px;
          z-index: 0;
          background: var(--gray-700);
          mix-blend-mode: lighten;
        }
    }

    .react-datepicker__month-container {
      padding: 20px;

      +.react-datepicker__month-container {
        position: relative;

        &:before {
          content: '';
          width: 1px;
          height: 315px;
          background: var(--gray-600);
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .react-datepicker__children-container {
      width: 100%;
      margin: 0;
      padding: 16px;
      display: flex;
      align-items: center;
      border-top: 1px solid var(--gray-600);
    }

    .react-datepicker__week {

      >div:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      >div:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    .react-datepicker__day--outside-month {
      opacity: 0;
      height: 0;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end{
      background: var(--blue-light-500, #0BA5EC)!important;
      color: var(--color-white) !important;
      border-radius: 50% !important;
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--in-range {
      border-radius: 50% !important;
    }

    .react-datepicker__navigation-icon {
      top: 7px;
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
      color: var(--color-white);
    }
  }
}

.dateValue {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 29px;
  text-align: center;
  width: 50%;
  gap: 12px;
  color: var(--gray-100, #F2F4F7);
  padding: 0 16px;
}

.dateValueInput {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  border-radius: 8px;
  border: 1px solid var(--gray-600, #475467);
  background: var(--gray-700, #344054);
  color: var(--gray-100, #F2F4F7);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  gap: 16px;
  margin-left: auto;
  color: var(--gray-400);
  font-size: 14px;
  padding: 0  16px;
}

.btn {
  flex: 1;
  color: var(--gray-400);
  border: 1px solid transparent;
  transition: .3s;

  @media (hover: hover) {
    &:hover {
      border: 1px solid var(--gray-600);
    }

  }
}

.error {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color:  var(--error-500, #F04438);;
  margin-bottom: 4px;
}

.row {
  display: flex;
  align-items: center;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;
}

.flex {
  display: flex;
  align-items: center;
}
