.sentimentButton {
  background: var(--gray-700);
  color: var(--color-white);
  padding: 10px 18px;
  border-radius: 8px;
  flex: 1;
  transition: background-color .3s;

  &.active {
    background-color: var(--gray-750);
  }
}

.sentimentsList {
  display: flex;
  gap: 8px;
}

.popupFooter {
  margin-top: 16px;

  button {
    width: 100%;
  }
}