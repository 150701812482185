@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 15px;
  height: calc(100vh - 24px);
  position: relative;
  overflow: hidden;

  @include for-size(xs) {
    grid-gap: 30px;
  }
}
