@import "styles/mixins";

.wrapper {
  background: var(--gray-650);
  border-radius: 24px;
  width: 100%;
  position: absolute;
  padding: 60px;
  top: calc(50% + 65px);
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 825px;
  text-align: center;
  box-shadow: 0 0 24px #101828;
  max-height: calc(100% - 65px);
  overflow: auto;
}

.imageWrapper {
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 20px;

  img {
    max-width: 100%;
  }
}

.title {
  margin-bottom: 35px;
  font-weight: 700;
}
