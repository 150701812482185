.label {
  color: var(--base-white, #FFF);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.sublabel {
  color: var(--gray-200, #EAECF0);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.ml {
  margin-left: auto;
  display: flex;
  gap: 8px;
}

.labelWrapper {
  width: 280px;
  flex-shrink: 0;
}

.labelRight {
  flex-grow: 1;
  margin-right: auto;
  max-width: calc(100% - 465px);
}