.wrapper {
  &.noBorder {
    :global {
      .MuiPaper-root {
        border: none;
        box-shadow: none;
      }
    }

    th, td {
      border: none;
    }
  }

  :global {
    .MuiPaper-root {
      margin-bottom: 0;
      background: var(--gray-900);
      border: 1px solid var(--gray-800);
      border-radius: 15px;
    }

    .MuiTableCell-head {
      font-size: 16px;
      font-weight: 700;
    }

    .MuiTableCell-root {
      border-bottom: 1px solid var(--gray-800);

      a {
        color: var(--blue-light-400);
      }
    }
  }
}
