.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    object-fit: cover;
  }
}

.dezenhall {
  height: 55px!important;
  width: auto!important;
}
