.legends {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  padding: 9px 8px;
  border-radius: 60px;

  &.activeLegend {
    background-color: var(--gray-700);
  }
}

.legendText {
  font-size: 14px;
  color: var(--gray-400);
  display: inline-block;
  line-height: 1;
}

.legendMarker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}
