.wrapper {
  padding: 32px 0 24px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}

.stickyFilters {
  position: sticky;
  top: 0;
  z-index: 15;
  background-color: var(--gray-950);
}

.companyFilters {
  padding-top: 10px;
}

.competitiveFilters {
  margin-top: 10px;
  padding: 20px 24px;
  border-radius: 24px 24px 0 0;
  background-color: var(--gray-800);
}