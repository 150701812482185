.outerLink {
  color: var(--blue-light-400, #36BFFA);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: relative;
  padding-bottom: 2px;
  transition: 0.3s;
  border-bottom: 1px solid transparent;
  margin-left: auto;
  width: fit-content;
  display: inline;
  word-break: break-all;

  &:hover {
    color: var(--blue-light-400, #36BFFA);
    border-bottom: 1px solid var(--blue-light-400, #36BFFA);
  }
}
