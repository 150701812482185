@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 15px;
  overflow: visible;

  &.topWrapper {
    padding: 32px 0 24px 0;
  }

  &:last-child {
    padding-bottom: 24px;
  }

  @include for-size(xs) {
    grid-gap: 30px;
  }
}

.stockTitle {
  padding: 10px 0;
  display: flex;
}

.competitiveHeader, .competitiveContent {
  display: flex;
  justify-content: space-between;
}

.competitiveItem {
  flex: 1;
}

.filterWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}