.wrapper {
  padding: 30px 24px;
  border-radius: 24px;
  border: 1px solid var(--gray-750);
  flex: 1;
}

.activePlan {
  background-color: var(--gray-650);

  .button {
    background-color: var(--gray-750);
    color: var(--color-white);
  }
}

.features {
  margin: 27px 0 36px 0;
}

.pricePeriod {
  font-size: 18px;
  margin-left: 10px;
}

.priceWrapper {
  margin-bottom: 30px;
}

.price {
  font-size: 30px;
  font-weight: 600;
}

.feature {
  font-weight: 600;
  font-size: 18px;
  position: relative;
  padding-left: 25px;

  &:before {
    content: "";
    width: 6px;
    height: 6px;
    background: var(--color-white);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  & + & {
    margin-top: 10px;
  }
}

.button {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  border: 1px solid transparent;
  color: var(--color-white);
  border-color: var(--gray-650);
  padding: 8px 30px;
  border-radius: 45px;

  &.enterprise {
    background-color: var(--color-white);
    color: var(--gray-950);
  }
}
