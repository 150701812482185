@import "styles/mixins";

.emptyTitle {
  font-size: 14px;
}

.skeletonWrapper {
  width: calc(50% - 15px);
}

.skeletonTopicItem {
  width: 100%;
}

.listWrapper {
  display: flex;
  gap: 24px 30px;
  flex-wrap: wrap;
}

.itemWrapper {
  width: calc(50% - 15px);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% - 3px);
    height: 100%;
    border-radius: 16px;
    z-index: 0;
    top: 0;
    left: -3px;
  }

  &.positive:before {
    background-color: var(--green-500);
  }

  &.negative:before {
    background-color: var(--error-500);
  }
}

.content {
  padding: 18px 24px;
  background-color: var(--gray-650);
  border-radius: 16px;
  z-index: 1;
  position: relative;
  height: 100%;
}

.heading {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
}

.topicsList {
  padding-left: 20px;
}

.topicItem {
  padding: 10px 0;
  color: var(--gray-300);
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  & + & {
    border-top: 1px solid var(--gray-750);
  }
}

.topicNumber {
  line-height: 26px;
  background-color: var(--gray-900);
  flex: 0 0 25px;
  text-align: center;
  border-radius: 8px;
}
