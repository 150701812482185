.wrapper {
  padding: 30px 22px 27px 22px;
  background-color: var(--gray-850);
  border-radius: 0 0 24px 24px;
  margin: -12px 0 0 -3px;
}

.heading {
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message {
  margin: 0;
}

.responderAnswer {
  color: var(--gray-400);
  font-size: 14px;
}

.imageWrapper {
  :global {
    .avatar {
      padding: 5px;
      background-color: var(--gray-650);

      &:before {
        content: '';
        background-color: var(--gray-650);
        width: 3px;
        height: 19px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
  }
}
