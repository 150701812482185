.toggleButton {
  font-weight: bold;
  cursor: pointer;
  color: var(--blue-light-400);
  margin-top: 5px;
  display: inline-block;
  padding-bottom: 2px;
  transition: 0.3s;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: var(--blue-light-400);
  }
}
