.bulletIcon {
  position: absolute;
  top: 7px;
  left: 0;
}

.bulletText {
  position: relative;
  color: var(--color-white);
  font-size: 15px;
  padding-left: 18px;

  & + & {
    margin-top: 15px;
  }
}