.footerActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}

.field {
  & + & {
    margin-top: 10px;
  }
}