.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
}

:global {
  #alertRemove {
    .MuiPaper-root {
      min-width: 300px;
      background: var(--gray-900);
      border:1px solid var(--gray-500);
    }
  }
}

.remove {
  color: var(--error-500);
}


