@import "styles/mixins";
.wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
}

.gaugeWrapper {
  width: 220px;
  height: 170px;
  position: absolute;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: top;
  right: -8px;
  top: 103%;
}
.infoIcon{
  position: relative;
  margin-left: 3px;
  top: 1px;
}