.loadingHiddenText {
  opacity: 0;
  visibility: hidden;
}

.loaderBar {
  position: absolute;
}

.inputFile {
  display: none;
}