.wrapper {
  margin: 15px 0;
  padding: 24px;
  background: var(--gray-900);
  position: relative;
}

.title {
  font-size: 22px;
  margin-bottom: 8px;
}

.headWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.note {
  margin-bottom: 10px;
}