.formWrapper {
  margin: 30px auto 0 auto;
  max-width: 550px;
}

.saveWrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.withError {
    justify-content: space-between;
  }
}

.error {
  color: var(--error-500);
  margin: 10px 0;
  text-align: left;
}

.companyCompetitorHeader {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 7px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--gray-500);
}

.companyImg {
  width: 30px;
  font-size: 0;
  margin-right: 8px;

  img {
    width: 100%;
  }
}

.companySymbol{
  margin: 0 8px;
  font-size: 12px;
}
