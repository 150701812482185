@import "styles/mixins";

.wrapper {
  background: var(--gray-650);
  border-radius: 24px;
  width: 100%;
  position: absolute;
  padding: 45px 60px;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 825px;
  text-align: center;
  box-shadow: 0 0 24px #101828;
}

.title {
  margin-bottom: 20px;
}

.subTitle {
  margin-bottom: 4px;
}
