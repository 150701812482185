.footerActions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}

.field {
  textarea {
    height: 150px;
  }

  & + & {
    margin-top: 10px;
  }
}

.disclaimerImg {
  max-width: 200px;
  margin-bottom: 10px;
}

.error {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: var(--error-500, #F04438);
}