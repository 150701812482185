@import "../../../../styles/mixins";

.icon {
  display: inline-grid;
  margin-right: 5px;
}


.btn {
  padding: 0 6px 12px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  position: relative;
  color: var(--gray-400);

  @include for-size(xs) {
    padding: 0 12px 12px 12px;
  }

  &:after {
    content: '';
    width: 0;
    height: 2px;
    background-color: var(--blue-light-500);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.2s;
  }

  &.active {
    color: var(--blue-light-600, #0086C9);
  }

  &.active,
  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.nowrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 29px;
  padding: 3px 10px;
  margin-left: 8px;
  border-radius: 16px;
  background: var(--gray-800, #1D2939);
  color: var(--color-white, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  span  {
    display: inline-block;
    min-width: 0;
  }
}
