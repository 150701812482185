@import "styles/mixins";

.wrapper {
  flex-grow: 1;
  grid-gap: 12px;
  position: sticky;
  left: 0;
  right: 0;
  z-index: 15;
  backdrop-filter: blur(10px);
  background-color: #1D2939;
  backdrop-filter: blur(10px);
  padding: 20px 24px;
  margin: -24px -24px;
  flex-wrap: wrap;
  border-radius: 24px 24px 0 0;
  top: 152px;

  &.user {
    top: 64px;
  }

  @include for-size(md) {
    flex-wrap: nowrap;
  }
}


.statisticTitle {
  font-size: 24px;
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.inputsWrapper {
  display: flex;
  gap: 24px;
  align-items: center;
}