.wrapper {
  display: flex;
  align-items: center;
}

.ava {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  flex-shrink: 0;

  &.withBorder {
    border: 3px solid transparent;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.post {
  width: 34px;
  height: 34px;
}

.post {
  width: 34px;
  height: 34px;
}


.label {
  color: var(--gray-400);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.nickName {
  color: var(--gray-500);
}

.border {
  border-color: var(--yellow-500 );
}

.relative {
  position: relative;
  margin-right: 8px;

  .crownIcon {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
  }
}
