.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  & + & {
    margin-top: 10px;
  }
}

.rowFields {
  display: flex;
  gap: 10px;
}
.row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.label {
  color: var(--system-colors-gray-200, #E0E3E6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

