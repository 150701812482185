@import "../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 16px;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.box {
  position: relative;
  min-height: 45px;
}

.connectionsWrapper {
  margin: 15px 0;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 32px;

  &.centered {
    align-items: center;
  }
}

.labelWrapper {
  width: 280px;
  flex-shrink: 0;
}

.title {
  font-size: 18px;
}

.label {
  color: var(--base-white, #FFF);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.labelRight {
  flex-grow: 1;
  margin-right: auto;
  max-width: calc(100% - 465px);
  display: flex;
  flex-direction: column;
}

.labelRightButton {
  margin-left: auto;
}

.logoWrapper {
  display: flex;
  align-items: flex-end;
  gap: 30px;
}