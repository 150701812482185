$xxs: 400px;
$xs: 575px;
$sm: 600px;
$md: 991px;
$lg: 1200px;
$xl: 1450px;
$xxl: 1900px;


@mixin for-size($size) {
  @if $size == xxs {
    @media (min-width: $xxs ) { @content; }
  }
  @else if $size == xs {
    @media (min-width: $xs ) { @content; }
  } @else if $size == sm {
    @media (min-width: $sm) { @content; }
  } @else if $size == md {
    @media (min-width: $md) { @content; }
  } @else if $size == lg {
    @media (min-width: $lg) { @content; }
  } @else if $size == xl {
    @media (min-width: $xl) { @content; }
  }
  @else if $size == xxl {
    @media (min-width: $xxl) { @content; }
  }
}

@mixin scrollbars() {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-800);
  }

  &::-webkit-scrollbar-track {
    background: var(--gray-700);
    border-radius: 4px;
  }
}
