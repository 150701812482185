.wrapper {
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;
  position: relative;
}

.chartWrapper {
  position: relative;
  z-index: 1;
}

.chartMarkerTooltip {
  position: absolute;
  width: 3px;
  height: calc(100% - 20px);
  background-color: var(--gray-650);
  top: 0;
  z-index: 0;
}

.markerTooltipDot {
  width: 35px;
  height: 35px;
  background-color: var(--gray-750);
  border-radius: 50%;
  display: inline-block;
  top: 50%;
  left: 50%;
  line-height: 35px;
  transform: translate(-50%, -50%);
  font-weight: bold;
  position: absolute;
  text-align: center;
  font-size: 20px;

  &.tooltipHeaderIcon {
    left: 10px;
    top: 0;
    width: 25px;
    height: 25px;
    font-size: 16px;
    line-height: 25px;
  }
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tooltipCards {
  display: flex;
  gap: 10px;
}

.tooltipCard {
  flex: 0 0 calc(33.33% - 5px);
  position: relative;
}

.removeTooltip {
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 50%;
  background: var(--gray-700);
  width: 22px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}

.tooltip {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: rgb(29 41 57 / 70%);
  padding: 16px;
  box-shadow: none;
  font-size: 16px;

  &.cardItem {
    padding: 35px 25px 16px 16px;
  }

  .nameWrapper {
    display: flex;
    align-items: center;
    width: 75px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .date {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px
  }

  .time {
    color: var(--gray-450);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .icon {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
    flex: 0 0 14px;
  }

  .nameWrapper + .colWrapper {
    margin-left: 30px;
  }

  .colWrapper {
    width: 72px;
    padding: 0 10px;
    text-transform: capitalize;
    font-size: 12px;

    &.title {
      color: var(--gray-450);
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.bigIndent + .row {
      margin-top: 20px;
    }

    & + .row {
      margin-top: 8px;
    }

    svg {
      margin-right: 5px;
      transform: translateY(-3px);
    }
  }

  .name {
    color: var(--gray-450);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.5px;
    text-transform: capitalize;
    width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .value {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.5px;
    margin-left: 5px;
  }
}
