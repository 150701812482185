.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px;
  transition: 0.3s;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: var(--gray-200);
  position: relative;
  margin: 0 1px;

  &.active,
  &:hover {
    border-radius: 8px;
    background: var(--gray-900, #101828);
  }

  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    touch-action: none;
    pointer-events: none;
  }
}

.picker {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 2;
}

.container {
  position: relative;
}
