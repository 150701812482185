@import "styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 15px;
  overflow: visible;
  gap: 24px;

  &.topWrapper {
    padding: 24px;
  }

  &:last-child {
    padding-bottom: 24px;
  }

  @include for-size(xs) {
    grid-gap: 30px;
  }
}

.topWrapper {
  padding: 24px;
}

.titleSection {
  margin-bottom: 24px;

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #f6f7f8;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: 14px;
    color: #98a2b3;
    display: flex;
    gap: 16px;
    align-items: center;

    .dateRange {
      &:before {
        content: '•';
        margin-right: 16px;
      }
    }
  }
}

.chartContainer {
  background-color: #101828;
  border: 1px solid #344054;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  
  canvas {
    max-height: 400px;
  }
}
