.wrapper {
  margin-left: -24px;
  margin-right: -24px;
  position: relative;
}

.componentWrapper {
  margin-top: 40px;

  & > div:first-child {
    padding-bottom: 0;
  }
}

.heading {
  padding-left: 20px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.headingText {
  & > div:first-child {
    margin-bottom: 0;
  }
}

.clickSectionMessage {
  font-size: 14px;
  color: var(--gray-400);
}

.chartWrapper {
  position: relative;
  z-index: 1;
  height: 430px;
  margin-bottom: 24px;

  & > div:first-child {
    position: relative;
    z-index: 1;
  }

  &.chatterWithClick {
    :global {
      .apexchart-summary-crosshair {
        opacity: 1;
      }

      .apexcharts-series[seriesName="Chatter"] .apexcharts-marker {
        opacity: 1;

        &[selected="true"] {
          cursor: pointer;
          r: 12;
          filter: none;
        }

        &:not([selected="true"]):hover {
          cursor: pointer;
          filter: none;
          r: 12;
        }
      }
    }
  }
}

.tooltip {
  width: 280px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: rgba(29, 41, 57, 0.7);
  padding: 12px 16px;
  box-shadow: none;
  backdrop-filter: blur(3px);
}

.tooltipTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.top {
  margin-bottom: 15px;
}

.date {
  color: #98A2B3;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px
}

.toolTipIconWrapper {
  width: 30px;
  display: flex;
}

.row {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  svg {
    margin-right: 5px;
    transform: translateY(-3px);
  }
}

.name {
  color: var(--gray-300);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  text-transform: capitalize;
  width: 60px;
}

.value {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.5px;
  margin-left: 5px;
}

.loaderOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
