@import "styles/mixins";

.listWrapper {
  margin: 0 -12px;

  @include for-size(xs) {
    margin: 0 -24px;
  }

  &.noMargin {
    margin: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 15px 30px;

  &.main {
    background-color: var(--gray-650);
  }
}

.barWrapper {
  flex: 1;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: var(--gray-750);
  flex: 0 0 46px;
  margin-right: 17px;
}

.mainStockText {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 7px;
  line-height: 1;
}

.bar {
  display: flex;
  align-items: center;
}

.skeletonWrapper {
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 15px 6px;
}

.barItem {
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #000;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }

  &.negative {
    background-color: var(--error-500);
    min-width: 50px;
  }

  &.positive {
    background-color: var(--green-500);
    min-width: 50px;
  }

  &.neutral {
    background-color: var(--system-colors-gray-300);
    flex: 1;
  }
}
