.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.static {
    position: static;
  }
}


.spinner {
  position: relative;
  width: 54px;
  height: 54px;
  display: inline-block;
  padding: 10px;
  border-radius: 10px;

  &>div {
    width: 6%;
    height: 16%;
    background: #FFF;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    animation: fade 1s linear infinite;
  }

  &>div:nth-child(1) {
    transform:rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }

  &>div:nth-child(2) {
    transform:rotate(30deg) translate(0, -130%);
    animation-delay: -0.9167s;
  }

  &>div:nth-child(3) {
    transform:rotate(60deg) translate(0, -130%);
    animation-delay: -0.833s;
  }
  &>div:nth-child(4) {
    transform:rotate(90deg) translate(0, -130%);
    animation-delay: -0.7497s;
  }
  &>div:nth-child(5) {
    transform:rotate(120deg) translate(0, -130%);
    animation-delay: -0.667s;
  }
  &>div:nth-child(6) {
    transform:rotate(150deg) translate(0, -130%);
    animation-delay: -0.5837s;
  }
  &>div:nth-child(7) {
    transform:rotate(180deg) translate(0, -130%);
    animation-delay: -0.5s;
  }
  &>div:nth-child(8) {
    transform:rotate(210deg) translate(0, -130%);
    animation-delay: -0.4167s;
  }
  &>div:nth-child(9) {
    transform:rotate(240deg) translate(0, -130%);
    animation-delay: -0.333s;
  }
  &>div:nth-child(10) {
    transform:rotate(270deg) translate(0, -130%);
    animation-delay: -0.2497s;
  }
  &>div:nth-child(11) {
    transform:rotate(300deg) translate(0, -130%);
    animation-delay: -0.167s;
  }
  &>div:nth-child(12) {
    transform:rotate(330deg) translate(0, -130%);
    animation-delay: -0.0833s;
  }

}

.text {
  color: var(--gray-100);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}
@keyframes fade {
   from {opacity: 1;}
   to {opacity: 0.25;}
 }
