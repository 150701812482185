.skeletonHead {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.skeletonItem {
  padding: 24px;
  background: var(--gray-650);
  border-radius: 16px;

  & + & {
    margin-top: 8px;
  }
}
