.box {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.chexBoxRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-left: -9px;
  flex-wrap: wrap;
  

  &>* {
    display: flex;
    align-items: center;
  }
}

.error {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color:  var(--error-500, #F04438);;
  margin-bottom: 10px;
}