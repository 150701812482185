.h1Title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.h2Title {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.h3Title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.h4Title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.h5Title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: var(--gray-iron-50);
}


.title {
  color: var(--color-white);
}