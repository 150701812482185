.text {
  color: var(--gray-400);
  font-size: 14px;
  text-align: center;
}

.footerActions {
  margin-top: 40px;
  display: flex;
  gap: 12px;

  & > * {
    flex: 1;
  }
}

.popup {
  h3 {
    font-size: 20px;
    text-align: center;
  }
}