.seriesFiltersWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 35px;
}

.seriesFilter {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 8px;
  color: var(--gray-600);
  display: flex;
  align-items: center;
  gap: 8px;

  &.active {
    background-color: var(--gray-800);
    color: var(--color-white);

    svg path {
      stroke: #fff;
    }
  }
}
