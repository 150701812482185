@import "styles/mixins";

.wrapper {
  max-width: 600px;
  width: 500px;
  border-radius: 24px;
  background-color: var(--gray-800);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  max-height: calc(100vh - 30px);
  display: flex;
  flex-direction: column;

  &.fullWidth {
    width: 100%;
  }

  &.normal {
    padding: 40px 45px;
  }

  &.small {
    padding: 24px;
    width: 440px;

    .heading {
      margin-bottom: 16px;
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  padding: 0 5px;
}

.closeIcon {
  cursor: pointer;
  transition: opacity .3s;
  &:hover {
    opacity: .8;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: rgba(12, 17, 29, .5);
}

.content {
  max-height: calc(100% - 40px);
  overflow: auto;
  padding: 0 5px;

  &.visibleOverflow {
    overflow: visible;
  }

  @include scrollbars();
}
