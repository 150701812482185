.wrapper {
  display: flex;
  flex-grow: 1;
  gap: 24px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  margin-bottom: 48px;
  border-bottom: 1px solid var(--gray-700);
}

.sublabel {
  color: var(--gray-200, #EAECF0);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}