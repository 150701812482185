.wrapper {
  padding: 32px 0 24px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
}

.titleWrapper {
  margin-bottom: 25px;
}

.stickyFilters {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(10px);
  background-color: var(--gray-950);
  padding-bottom: 5px;
}

.finIntFiltersWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  margin-bottom: 6px;
}