@import "styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 9px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  transition: 0.3s;
  position: relative;

  color: #98A2B3;

  & .title {
    display: none;
  }

  svg, img {
    max-width: 20px;
    max-height: 20px;
  }

  @include for-size(xs) {
    padding: 10px 18px;
    justify-content: flex-start;
    .title {
      display: inline;
    }
    svg, img {
      margin-right: 8px;
    }
  }

  &.isActive {
    background: var(--gray-700);
    color: var(--color-white);
  }

  &[disabled] {
    opacity: 0.8;
  }
}

.loader {
  position: absolute;
  right: 45%;
  top: 25%;
  z-index: 2;
}

.transparent {
 color: var(--gray-400);

  svg, img {
    opacity: 0.5;
  }
}

.countersWrapper {
  font-size: 14px;
  padding: 5px;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  overflow: hidden;
}

.flex {
  display: flex;
  align-items: center;
  flex: 1;
}

