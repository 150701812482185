@import "styles/mixins";

.wrapper {
  display: flex;
  grid-gap: 12px;
  overflow-x: auto;
  padding-bottom: 5px;

  @include scrollbars();

  @include for-size(md) {
    flex-wrap: wrap;
  }
}
