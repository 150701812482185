@import "styles/mixins";

.wrapper {
  width: 250px;
  padding: 32px 12px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: var(--gray-950);
  position: fixed;
  top: 72px;
  left: 0;
  bottom: 0;
  z-index: 1;
  border-right: 1px solid var(--gray-700);
  transform: translateX(-100%);
  transition: 0.3s;

  &.isActive {
    transform: translateX(0);
  }

  @include for-size(lg) {
    width: 300px;
    position: static;
    padding: 32px 0 0;
    transform: none;
    border: none;
  }
}

.menuIcon {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 2;
  @include for-size(lg) {
    display: none;
  }

  span {
    display: flex;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--gray-700);
    width: 100%;
    height: 2px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: var(--gray-700);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
  }

  &:before {
    top: 4px;
  }

  &:after {
    bottom: 4px;
  }

  &.isActive {
    background: transparent;

    span {
      opacity: 0;
    }

    &:before {
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(45deg);
    }
    &:after {
      top: 50%;
      left: 0;
      transform:  translateY(-50%) rotate(-45deg);
    }
  }
}