.wrapper {
  background: var(--gray-900, #101828);
  border: 1px solid var(--system-colors-gray-600, #464D59);
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px 8px;
  position: absolute;
  width: 100%;
}

.suggestionItem {
  padding: 8px 15px;
  border-radius: 8px;
  transition: background-color .1s linear;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: var(--gray-650);
  }
}
