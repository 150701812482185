.remove {
  margin: auto 0 auto auto;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  >div {
    width: 100%;
  }

  margin-bottom: 16px;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}

.labelWrapper {
  width: 280px;
  flex-shrink: 0;
}

.label {
  color: var(--base-white, #FFF);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.labelRight {
  flex-grow: 1;
  margin-right: auto;
  max-width: calc(100% - 465px);
  display: flex;
  flex-direction: column;
}