@import "../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 15px;

  @include for-size(xs) {
    grid-gap: 30px;
  }
}

.vAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.container {
  width: 100%;
  padding: 64px 0px!important;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100svh;
  gap: 16px;

  @media (max-width: 900px){
    padding: 15px
  }
}

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.imageGrid {
  border-left: 1px solid var(--gray-700);
}

.video {
  margin: auto auto 0 -8px;
  video {
    max-width: 100%;
  }
}

.image {
  margin: 0;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.root {
  background: radial-gradient(at -50% -50%, rgba(236,72,153,1) 0, #0c121e 50%);
}

.mainWrapper {
  //max-width: 1440px;
  //margin: 0 auto;
  padding-bottom: 0;
  padding-top: 0;
}
