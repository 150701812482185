.row {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .sourceData {
    flex: 0 0 150px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .btn {
    margin-left: 30px;
    margin-top: 15px;
  }  