.inputWrapper {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  flex: 1;
}

.labelWrapper {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  color: var(--system-colors-gray-200, #E0E3E6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.input {
  width: 100%;
  min-height: 44px;
  border-radius: 8px;
  border: 1px solid var(--system-colors-gray-600, #464D59);
  background: var(--gray-900, #101828);
  box-shadow: 0 1px 2px 0 rgba(13, 16, 23, 0.24);
  padding: 8px 14px;
  outline: none;
  transition: 0.3s;
  color: var(--system-colors-gray-400, #99A0A8);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &[disabled] {
    opacity: .7;
    cursor: not-allowed;
  }

  &:not(.noFocus):focus {
    border-color: var(--system-colors-gray-400, #99A0A8);
  }

  &::placeholder {
    opacity: 0.5;
  }
}

.error {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  color:  var(--error-500, #F04438);;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  border: none;
  transition: 0.3s;
}

.iconLeft {
  left: 14px;
}

.iconRight {
  right: 14px;
  cursor: pointer;
}

.relative {
  width: 100%;
  position: relative;
}

.flex {
  display: flex;
  align-items: center;
}

.leftPadding {
  padding-left: 42px;
}

.rightPadding {
  padding-right: 42px;
}

.hover {
  &:hover {
    opacity: 0.8;
  }
}

.prefix {
  border-radius: 8px 0 0 8px;
  border: 1px solid var(--gray-600, #475467);
  padding: 9px 14px;
  color: var(--gray-200);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background: var(--gray-900);
}

.leftBorder {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rangePickerWrapper {
  position: fixed;
  transform: translateX(-10%);
  z-index: 1;
}

.clickable {
  cursor: pointer;
}

@media all and (max-height: 1080px) {
  .rangePickerWrapper {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
