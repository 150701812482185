.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 320px;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  font-size: 82px;
  display: flex;
  margin-bottom: 15px;
  color: var(--gray-450);
}

.message {
  color: #98A2B3;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}