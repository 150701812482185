@import "../../../styles/mixins";

.wrapper {
  display: flex;
  flex-grow: 1;
  grid-gap: 16px;
  border-bottom: 1px solid var(--gray-500);
  margin-bottom: 27px;
  justify-content: space-between;
  position: relative;
  min-height: 37px;

  @include for-size(xs) {
    justify-content: flex-start;
  }
}
