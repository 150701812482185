.wrapper {
  min-width: 240px;
  border: 1px solid transparent;
  border-radius: 8px;
  position: absolute;
  right: 3px;
  top: calc(100% + 10px);
  z-index: 10;
  transform: translateY(-20px);
  opacity: 0;
  transition: 0.3s;
  visibility: hidden;

  &.active {
    transform: translateY(0);
    opacity: 1;
    width: auto;
    visibility: visible;
  }
}