.point {
  position: absolute;
  border-radius: 50%;
  top: -5px;
  font-size: 12px;
  z-index: 1;
  width: 24px;
  height: 24px;
  background-color: var(--gray-750);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E9D7FE;
  cursor: pointer;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #E9D7FE;
    display: block;
    transform: rotate(225deg);
    position: absolute;
    border-right: 0;
    border-bottom: 0;
    bottom: -4px;
  }
}

.additionalWrapper {
  margin-top: 8px;
}

.prMessage {
  cursor: text;
  font-size: 14px;
  position: absolute;
  background: #192434;
  padding: 16px;
  width: 380px;
  border-radius: 8px;
  left: -11px;
  top: 35px;
  border: 1px solid var(--gray-700);
  animation: fadeIn 0.4s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.prTitle {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
}

.readMore {
  font-size: 16px;
  font-weight: 500;
  color: #B692F6;

  &:hover {
    color: #B692F6!important;
    text-decoration: underline!important;
  }
}

.date {
  color: #98A2B3;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  color: #98A2B3;
  cursor: pointer;
}

.stockInfo {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  position: relative;
}

.stockCompany {
  font-size: 16px;
  font-weight: 600;
  max-width: 245px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  margin-bottom: 3px;
}

.logo {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.triangle {
  width: 10px;
  height: 10px;
  background-color: #192434;
  border: 1px solid var(--gray-700);
  display: block;
  transform: rotate(45deg);
  position: absolute;
  top: -6px;
  border-right: 0;
  border-bottom: 0;
  border-radius: 2px;
}