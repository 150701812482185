@import "styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.labelRight {
  width: 300px;
  margin-left: auto;
}

.selectStockWrapper {
  margin-bottom: 15px;
}

.uiButtonConfig {
  display: flex;
  justify-content: flex-end;
}
