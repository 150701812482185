.wrapper {
  padding: 12px 27px;
  border-radius: 8px;
  background-color: var(--gray-550);
  display: flex;
  align-items: center;
}

.content {
  padding-left: 30px;
  text-align: left;
  max-width: calc(100% - 35px);
  font-weight: 400;
  line-height: 28px;
}
