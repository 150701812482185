@import "styles/mixins";

.wrapper {
  & + & {
    margin-top: 16px;
  }
}

.itemWrapper {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% - 3px);
    height: 100%;
    border-radius: 16px;
    z-index: 0;
    left: -3px;
  }

  &.positive:before {
    background-color: var(--green-500);
  }

  &.negative:before {
    background-color: var(--error-500);
  }
}

.closeIcon {
  color: var(--gray-450);
  cursor: pointer;
}

.content {
  padding: 24px;
  background: var(--gray-650);
  border-radius: 16px;
  position: relative;
  z-index: 1;
}

.userWrapper {
  margin-bottom: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.message {
  margin: 0;

  &.withScroll {
    max-height: 125px;
    overflow: auto;
    padding-right: 10px;

    @include scrollbars();
  }
}

.footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 16px
}

.contentWithIconRow {
  color: var(--gray-400);
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
}

.footerContent {
  display: flex;
  align-items: center;
  gap: 24px;
}
