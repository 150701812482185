.submitWrapper {
  text-align: right;
  margin-top: 17px;
}

.textArea {
  background-color: var(--gray-750);
  border: none;
  height: 200px;
  resize: none;
  padding: 22px 17px;

  &::placeholder {
    opacity: 1;
    color: var(--gray-400);
  }
}

.heading {
  margin-bottom: 10px;
}
