.backToTop {
  position: fixed !important;
  bottom: 15px !important;
  right: 15px !important;
  z-index: 1300;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  
  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease-in-out;
  }
} 