.stock {
  padding: 2px 6px;
  background-color: var(--gray-800);
  border-radius: 4px;
  display: inline-block;
}

.timeFrame {
  font-weight: 500;
  color: #9AABCB;
  display: block;
}

.buttonIcon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-650);
  border-radius: 50%;
  margin-left: auto;

  svg {
    font-size: 18px;
    color: var(--color-white);
  }
}
