.wrapper {
  border-radius: 24px;
  border: 1px solid #FF9761;

  &.bad-account, &.collect-documentation {
    border-color: var(--error-400);

    .iconWrapper {
      background-color: var(--error-400);
    }
  }

  &.make-pr {
    border-color: var(--orange-500);

    .iconWrapper {
      background-color: var(--orange-500);
    }
  }

  & + & {
    margin-top: 16px;
  }
}

.iconNameWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.content {
  padding: 24px 24px 40px 24px;
}

.heading {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message {
  margin: 0;
  max-width: 700px;
}

.iconWrapper {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.dataSourceIcon {
  margin-left: 5px;
}

.bottomSection {
  padding: 10px 24px;
  color: var(--gray-400);
  background-color: var(--gray-650);
  border-radius: 0 0 26px 26px;
}

.link {
  display: inline-block;
  font-weight: 700;
  color: #069CDF;

  &:hover {
    color: #58c5f5;
  }
}

.actionButtons {
  display: flex;
  gap: 10px
}
