.wrapper {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  position: relative;
}

.label {
  border-radius: 6px;
  padding: 2px 8px;
  border: 1px solid var(--gray-600, #475467);
  background: var(--gray-800, #1D2939);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #A1A7BB;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 6px;

  &.new {
    color: var(--green-light-500, #66C61C);
    border: 1px solid var(--green-light-500, #66C61C);
    background: transparent;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-left: 10px;
  }
}

.typesSelect {
  width: 200px;
}
