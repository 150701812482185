.card {
  padding: 10px;
  flex: 1;

}

.title {
  font-size: 18px;
  margin-bottom: 10px;
}

.changeValue {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px 7px;
  border-radius: 20px;
  line-height: 24px;

  &.alerts {
    background-color: var(--yellow-400);
  }

  &.positive {
    color: var(--color-white);
    background-color: var(--green-500);
  }

  &.negative {
    color: var(--color-white);
    background-color: var(--error-500);
  }

  .value {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.noData {
  color: var(--gray-600);
  font-size: 14px;
}

.warning {
  color: var(--yellow-200);
}

.positive {
  color: var(--green-500);
}

.positiveLight {
  color: var(--green-300);
}

.negativeLight {
  color: var(--error-400);
}

.negative {
  color: var(--error-500);
}

.stocksList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stockData {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.stockInfo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.stockSymbol {
  display: inline-block;
  width: 55px;
  font-size: 15px;
  cursor: pointer;
}