.icon {
  width: 24px;
  font-size: 0;
  flex-shrink: 0;
  margin-right: 15px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 16px 22px 16px 36px;
  position: relative;
  transition: .3s;
  border-bottom: 1px solid var(--gray-800);
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  text-align: left;

  &.active {
    background: var(--gray-800);
  }

  &.hide {
    display: none;
  }

  &:hover {
    background: var(--gray-800);
    opacity: 0.7;
  }

  &:before {
    content: '';
    position: absolute;
    left: 16px;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-white);
    transform: translateY(-50%);
    display: none;
  }

  &.isNew {
    &:before {
      display: block;
    }
  }
}

.sourceTitleIcon {
  display: flex;
  margin: 0 5px;
}

.content {
  flex: 1;
}

.loading {
  margin: 6px;
  border-radius: 8px;
}

.titleText {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.mb {
  margin-bottom: 4px;
}
