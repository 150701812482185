.stockField {
  margin-top: 10px;
}

.topicsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.topicRow {
  display: flex;
  align-items: center;
  gap: 15px;
}

.addTopicButtonWrapper {
    display: flex;
    justify-content: flex-end;
}