.header {
  display: flex;
  align-items: center;
  min-height: 72px;
  border-bottom: 1px solid var(--gray-700);
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.labelRight {
  position: relative;
  width: 300px;
}

.blurContent {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}
