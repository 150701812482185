@import "styles/mixins";

.titleWrapper {
  display: flex;
  align-items: center;
}

.titleIcon {
  width: 30px;
  height: 30px;
  background-color: var(--gray-750);
  border-radius: 50%;
  margin-right: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
}

.wrapper {
  display: flex;
  flex: 1;
  min-height: 173px;
  min-width: 250px;
  position: relative;

  @include for-size(xxs) {
    flex: 1 0 calc(50% - 12px);
  }

  @include for-size(md) {
    flex: 1;
    max-width: 30%;
  }
}

.card {
  padding: 18px 16px!important;
}
