.wrapper {
  border: 1px solid var(--gray-700);
  border-radius: 20px;
  background-color: var(--gray-800);
}

.bars {
  display: flex;
}

.percentageMark {
  position: absolute;
  z-index: 1;
  border: 2px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .markTooltip {
      opacity: 1;
    }
  }
}

.markTooltip {
  opacity: 0;
  transition: opacity .3s;
  background-color: var(--gray-100);
  color: var(--gray-800);
  font-size: 10px;
  position: absolute;
  top: -10px;
  transform: translateY(-100%);
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 500;

  &:after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    border-right: solid 5px transparent;
    border-left: solid 5px transparent;
    border-top: solid 5px var(--gray-100);
    left: 50%;
    transform: translate(-50%, calc(100% - 2px));
    bottom: 0;
  }
}

.bar {
  flex: 1;
  padding: 2px;
  text-align: center;
  font-size: 10px;
  color: var(--gray-500);
  position: relative;

  &:not(:last-child) {
    border-right: 1px solid var(--gray-700);
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.low {
  color: var(--color-white);
  background-color: var(--green-500);
}

.medium {
  color: var(--color-white);
  background-color: var(--yellow-300);
}

.high {
  color: var(--color-white);
  background-color: var(--yellow-600);
}

.critical {
  color: var(--color-white);
  background-color: var(--error-500);
}