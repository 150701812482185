.wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 48px;
  height: 48px;


  svg {
    width: 100%;
    height: 100%;
  }
}