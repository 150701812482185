.summaryWrapper {
  height: 0;
  overflow: hidden;
  transition: height .6s;
  position: relative;
  z-index: 1;
}

.summaryItem {
  background-color: var(--gray-950);
  border-radius: 0 0 12px 12px;
  padding: 16px 24px;
  position: absolute;
  width: 100%;

  &.currentSummaryItem {
    animation: slideIn 1s;
  }

  &.prevSummaryItem {
    animation: slideOut 1s;
    transform: translateY(1000%);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.summaryCloseIcon {
  color: #98A2B3;
  cursor: pointer;
}

.summaryTitleWrapper {
  margin-bottom: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-white);
}