.title {
  margin-bottom: 20px;
}

.wrapper {
  padding-bottom: 0;
}

.arrowCircle {
  position: relative;
  left : -5px;
  width: 34px; 
  height: 34px; 
  border: 1px solid var(--gray-500); 
  border-radius: 50%;
  

  .redArrow {
    color: var(--error-500); 
  }
  .grayArrow {
    color: var(--gray-150); 
  }

}