@import "styles/mixins";

.wrapper {
  max-height: 592px;
  overflow-y: auto;
  padding-right: 5px;
  margin-right: -5px;
  margin-left: 0;

  @include scrollbars();
}
