.stepTitle {
  font-size: 20px;
  margin-bottom: 10px;
}

.dialogActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  button {
    width: 100%;
  }
}

.selectedStockListProp {
  margin-top: 8px;
  font-size: 14px;
}
