.autocompleteWrapper {
  margin-bottom: 16px;
}

.selectedStocks {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 10px;
}

.selectedStock {
  padding: 10px 12px;
  border: 1px solid var(--gray-500);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--gray-800);
  border-radius: 6px;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.removeSelectedStock {
  color: var(--gray-400);
  cursor: pointer;
}
