.wrapper {
  margin-left: -24px;
  margin-right: -24px;
  position: relative;

  & > div:first-child {
    position: relative;
    z-index: 1;
  }

  :global {
    .apexchart-summary-crosshair {
      opacity: 1;
    }
  }

  &.withClick {
    :global {
      .apexchart-summary-crosshair {
        opacity: 1;
      }

      .apexcharts-series[seriesName="Positive"] .apexcharts-marker,
      .apexcharts-series[seriesName="Negative"] .apexcharts-marker {
        opacity: 1;

        &[selected="true"] {
          cursor: pointer;
          r: 12;
          filter: none;
        }

        &:not([selected="true"]):hover {
          cursor: pointer;
          filter: none;
          r: 12;
        }
      }
    }
  }
}

.prOverlay {
  height: calc(100% - 26px);
}

.tooltip {
  width: 280px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #1D293980;
  padding: 15px;
  box-shadow: none;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}

.date {
  color: #ffffff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px
}

.time {
  color: var(--gray-450);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin-right: 10px;
}

.row {
  display: flex;
  align-items: center;

  &>div {
    margin-bottom: 5px;
  }

  svg {
    margin-right: 5px;
    transform: translateY(-3px);
  }
}

.name {
  color: var(--gray-450);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.5px;
  text-transform: capitalize;
}

.value {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.5px;
  margin-left: 5px;
}

.pb {
  padding-bottom: 0;
}
